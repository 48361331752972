import React, { useState } from "react"

// Components
import ShowcaseTile from "../showcaseTile"

const ShowCase = ({ data }) => {
  const [modal, setModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const showDetails = data => {
    setSelectedItem(data)
    toggle(true)
  }
  const toggle = val => {
    setModal(val)
  }
  const largeShowcaseIndex = [0, 3, 4, 7, 8, 11, 12]
  return (
    <div className="grid w-full grid-cols-1 gap-8 mb-6 md:grid-cols-3">
      {data.map(item => {
        return <ShowcaseTile data={item} />
      })}
    </div>
  )
}

export default ShowCase
