import React from "react"
import ShowCase from "../../OurWork/showCase"

const OurWork = ({ data: { selectedWorkTitle, selectedWork } }) => {
  return (
    <div className="max-w-6xl mx-auto">
      <h3 className="px-4 my-8 text-2xl font-bold text-white uppercase md:text-4xl siteFont md:px-8 xl:px-0">
        {selectedWorkTitle}
      </h3>
      <ShowCase data={selectedWork} />
    </div>
  )
}

export default OurWork
