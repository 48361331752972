import React, { useContext } from "react"
import Img from "gatsby-image"
import RichTextRenderer from "../../RichTextRenderer"

// Background Image component
import Background from "../../backgroundImage"

// Context API data
import { AppContext } from "../../../store/AppContext"

const Banner = ({
  data: { bannerTitle, bannerImage, bannerDescription },
  customNav,
}) => {
  const { toggle } = useContext(AppContext)
  return (
    <Background className={customNav ? "pt-8 md:pt-16" : ""}>
      <div className="max-w-6xl px-4 mx-auto md:px-8 xl:px-0 commonBanner">
        <div className="flex flex-wrap-reverse md:flex-no-wrap">
          <div className="w-full">
            <div className="py-4 font-bold text-white md:pt-12 siteFont">
              <h1 className="text-2xl uppercase md:text-5xl">{bannerTitle}</h1>
            </div>
            <div className="mt-4 Gray md:text-lg">
              {RichTextRenderer(bannerDescription.json)}
            </div>
            <button
              onClick={() => toggle(true)}
              className="inline-block px-8 py-4 my-4 text-lg text-center text-white capitalize transition duration-300 ease-in-out rounded-full md:block md:text-2xl focus:outline-none md:my-8 lg:w-5/12 bg-orange siteFont hover:bg-darkOrange"
            >
              get started now
            </button>
          </div>
          <div className="relative w-3/5 mt-12 lg:w-10/12">
          <object className="w-full" type="image/svg+xml" data={bannerImage.file.url}/>
        
          </div>
        </div>
        <hr className="mt-6 md:mt-12 xl:mx-auto" />
      </div>
    </Background>
  )
}

export default Banner
