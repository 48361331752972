import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Banner from "../../components/Services/AnnualReportGraphicDesign/banner"
import OurWork from "../../components/Services/Marketing/ourWork"
import AwardSection from "../../components/awardSection"
import Slider from "../../components/slider"
import ContactUs from "../../components/contactUs"
import Estimator from "../../components/PricingCalculator/estimator"

// Context API data
import { AppContext } from "../../store/AppContext"
import ContentSection from "../../components/Services/AnnualReportGraphicDesign/contentSection"
import ContentListSection from "../../components/Services/AnnualReportGraphicDesign/contentListSection"

const AnnualReportPage = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    bannerDescription,
   
    selectedWorkTitle,
    selectedWork,
    sections,
    ourApproachTitle,
    ourApproachSubtitle,
    ourApproachDescription,
    ourApproachSteps,
    ourApproachEndingNotes,
  } = data.annualReport

  const bannerData = {
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    bannerDescription,
  }

  const showcaseWork = { selectedWorkTitle, selectedWork }
  

  return (
    <Layout>
      <SEO
        title={ogTitle || "Marketing &amp; Branding"}
        description={ogDescription}
      />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />

      {sections?.map((data, i) => (
        <ContentSection
          key={i}
          alignment={i % 2 === 0 ? "left" : "right"}
          heading={data?.title}
          text={data?.description}
          img={data?.image}
        />
      ))}
      <ContentListSection
        heading={ourApproachTitle}
        text={ourApproachDescription}
        subHeading={ourApproachSubtitle}
        bottomText={ourApproachEndingNotes}
        list={ourApproachSteps}
      />
     
      <Estimator singleService service="Report_Design" />
      <AwardSection />
      <OurWork data={showcaseWork} />
      <Slider blueBackground />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query AnnualReportPageQuery {
    annualReport: contentfulAnnualReportGraphicDesignPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerImage {
       
        file{
          url
        }
      }
      bannerDescription {
        json
      }
      
     
      
      selectedWorkTitle
      selectedWork {
        id
        slug
        image {
          id
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      
      sections {
        title
        image {
         file{
          url
         }
        }
        description {
          json
        }
      }
      ourApproachTitle
      ourApproachSubtitle
      ourApproachDescription {
        json
      }
      ourApproachSteps {
        title
        description {
          json
        }
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      ourApproachEndingNotes {
        json
      }
      
    }
  }
`

export default AnnualReportPage
