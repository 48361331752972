import React from "react"
import Img from "gatsby-image"

import "./style.scss"

import RichTextRenderer from "../../RichTextRenderer"
import { BLOCKS } from "@contentful/rich-text-types"

const options = {
  renderNode: {
    [BLOCKS.HEADING_4]: (_, children) => {
      return <h2 className="text-white md:text-xl">{children}</h2>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="my-4">{children}</p>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-none detail-list-css list-outsite">{children}</ul>
    ),
  },
}
const ContentListSection = ({
  heading,
  text,
  subHeading,
  list,
  bottomText,
}) => {
  return (
    <div className={`max-w-6xl mx-auto px-4 md:px-8 xl:px-0 `}>
      <div className="pt-12 lg:pt-24">
        <div className="flex flex-col items-center justify-start lg:flex-row gap-112-css ">
          <div className={`flex flex-col justify-start items-start`}>
            {heading && (
              <h2 className="siteFont text-42-css text-white uppercase text-[42px] leading-[62px] font-semibold">
                {heading}
              </h2>
            )}
            {text && (
              <div className="mt-8 text-primary">
                {RichTextRenderer(text.json, options)}
              </div>
            )}
            {subHeading && (
              <h3 className="mt-12 mb-8 text-white siteFont text-35-css">
                {subHeading}
              </h3>
            )}
            {list && <div className="flex flex-col items-center w-full gap-5 justify-items-start">
              {list.map((item, i) => (
                <div
                  key={i}
                  className="flex flex-col items-center w-full gap-3 p-5 box-color-css br-15-css lg:flex-row justify-items-start xl:gap-6"
                >
                  <div className="img-160-css">
                  <Img fluid={item.image.fluid} />
                  </div>
                  <div className="w-full">
                    {item?.title && <h3 className="mb-3 text-center text-white siteFont lg:text-left text-35-css">
                      {item.title}
                    </h3>}
                    {item?.description && <p className="text-center text-primary lg:text-left">
                      {RichTextRenderer(item?.description.json,options)}
                    </p>}
                  </div>
                </div>
              ))}
            </div>}
            {bottomText && (
              <div className="mt-12 text-primary">
                {RichTextRenderer(bottomText.json, options)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentListSection
