import React from "react"
import Img from "gatsby-image"

import "./style.scss"

import RichTextRenderer from "../../RichTextRenderer"
import { BLOCKS } from "@contentful/rich-text-types"

const options = {
  renderNode: {
    [BLOCKS.HEADING_4]: (_, children) => {
      return <h2 className="text-white md:text-xl">{children}</h2>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="my-4">{children}</p>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="pl-6 list-disc list-outsite md:pl-12">{children}</ul>
    ),
  },
}
const ContentSection = ({
  heading,
  text,
  img,
  alignment,
  imgType,
  roundedImg,
  gap,
  imgWidth,
  imgHeight,
}) => {
  
  return (
    <div className={`max-w-6xl mx-auto px-4 md:px-8 xl:px-0  `}>
      <div className="border-b-2 border-solid padding-100-css border-color-css">
        <div
          className="flex flex-col justify-start xl:flex-row gap-res-css xl:items-center "
          style={gap ? { gap: gap + "px" } : { gap: "112px" }}       
        >
          <div
            className={`${
              alignment === "left" ? "order-1" : "order-1 lg:order-2"
            } ${
              imgType === "full"
                ? "flex-auto w-300-css self-stretch res-img-css"
                : "img-dimensions-css"
            } ${roundedImg ? "br-15-css" : ""} overflow-hidden w-full `}
            style={
              imgType === "full" ? { maxWidth: "370px", width: "100%" } : {}
            }
          >
            
             <div className="relative">
            <img src={img.file.url} />
          </div>
          </div>
          <div
            className={`${
              alignment === "left" ? "order-2" : "order-2 lg:order-1"
            } flex flex-col justify-start items-start`}
          >
            {heading && (
              <h2 className="siteFont text-42-css text-white uppercase text-[42px] leading-[62px] font-semibold">
                {heading}
              </h2>
            )}
            {text && (
              <div className="mt-8 text-primary md:text-lg">
                {RichTextRenderer(text.json, options)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentSection
